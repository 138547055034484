<template>
  <div class="entry" itemscope itemtype="https://schema.org/DefinedTerm">
    <picture class="entry-image-wrap cf">
      <img
        :src="image.url || '~/assets/images/cs-avatar.png'"
        :alt="imageAlt"
        :role="!image.url && 'none'"
        class="entry-image"
        itemprop="image"
      />
    </picture>
    <h3 :id="slug" level="3" :no-margin="true" class="entry-headline">
      <span itemprop="name">
        {{ name }}
      </span>
      <a aria-hidden="true" class="entry-link" :href="`#${slug}`" role="none">
        <SvgIcon icon="link" class="linkicon"
      /></a>
      <meta itemprop="disambiguatingDescription" :content="describedAs" />
      <meta itemprop="description" :content="profile" />
    </h3>
    <div class="entry-content">
      <template v-if="describedAs">
        <h4 class="entry-label">Description:</h4>
        <Paragraph size="small" class="entry-text">
          {{ describedAs }}
        </Paragraph>
      </template>

      <template v-if="profile">
        <h4 class="entry-label">Olfactive Profile:</h4>
        <Paragraph size="small" class="entry-text">
          {{ profile }}
        </Paragraph>
      </template>
      <template v-if="didYouKnow">
        <h4 class="entry-label">Facts:</h4>
        <Paragraph :text="didYouKnow" class="entry-text" size="small">
          {{ didYouKnow }}
        </Paragraph>
      </template>
    </div>
    <div v-if="products.length" class="entry-products-wrap">
      <h4 class="entry-label entry-products-label">
        Products with {{ name }}:
      </h4>
      <div class="entry-products">
        <ProductLink
          v-for="(product, index) in sortedProducts"
          :key="name + index + noteId + product.id"
          :url-path="product.urlPath"
          :srcset="product.imageSrcset"
          :src="product.imageUrl"
          :alt="product.imageAlt"
          :name="product.name"
        />
      </div>
      <nuxt-link
        v-if="showMoreLink"
        :to="`/fragrance-oil-finder/?${name}=searchable_notes%3A%22${name}%22`"
        class="show-more-link"
      >
        Show all products with {{ name }}
        <SvgIcon icon="chevron-right" class="icon" />
      </nuxt-link>
    </div>
    <meta
      itemprop="inDefinedTermSet"
      :content="`${$config.public.baseURL}/glossary`"
    />
  </div>
</template>

<script>
  import createFormatArray from "~/mixins/createFormatArray"
  export default {
    mixins: [createFormatArray],
    props: {
      noteId: {
        type: String,
        default: "",
      },
      name: {
        type: String,
        default: "",
      },
      describedAs: {
        type: String,
        default: "",
      },
      profile: {
        type: String,
        default: "",
      },
      didYouKnow: {
        type: String,
        default: "",
      },
      products: {
        type: Array,
        default: () => [],
      },
      image: {
        type: Object,
        default: () => {},
      },
      imageAlt: {
        type: String,
        default: "",
      },
      slug: {
        type: String,
        default: "",
      },
    },
    data() {
      return {
        showMoreLink: false,
      }
    },
    computed: {
      sortedProducts() {
        if (this.products.length > 4) {
          this.toggleShowMoreLink()
          return this.products.slice(0, 4)
        }
        return this.products
      },
    },
    methods: {
      toggleShowMoreLink() {
        this.showMoreLink = true
      },
    },
  }
</script>

<style scoped lang="scss">
  // Generals
  .entry-label {
    @apply h6;
    margin: 0;
  }
  .entry-text {
    font-family: $font-family-default;
    margin-bottom: $space-s;
    line-height: 1.5;
  }
  .linkicon {
    visibility: hidden;
    width: $base-spacing * 4;
    height: $base-spacing * 4;
  }

  .entry {
    display: grid;
    grid-column-gap: $space-m;
    grid-template-areas: "image headline ws" "image content products";
    grid-template-columns: auto 1fr $base-spacing * 70;
    margin-bottom: $space-m;
    padding-bottom: $space-m;
    border-bottom: 2px solid $gray-100;
    @include viewport("mini", "sm") {
      display: block;
    }
    &:last-child {
      border-bottom: none;
    }
  }

  // Container
  .entry-headline {
    grid-area: headline;
  }

  .entry-image-wrap {
    grid-area: image;
    @include viewport("mini", "sm") {
      margin-left: $space-s;
      float: right;
    }
  }
  .entry-content {
    grid-area: content;
    @include viewport("lg") {
      padding-right: $space-l * 2;
    }
    @include viewport("md") {
      padding-right: $space-l;
    }
  }

  .entry-products-wrap {
    grid-area: products;
  }

  // Specifics
  .entry-image {
    width: $base-spacing * 25;
    height: $base-spacing * 25;
    object-fit: contain;
    @include viewport("mini") {
      width: $base-spacing * 18;
      height: $base-spacing * 18;
    }
  }
  .entry-headline {
    word-break: break-all;
    align-items: center;
    margin: 0;
    margin-bottom: $space-s;
    @apply h3;

    &:hover {
      .linkicon {
        visibility: visible;
      }
    }

    @include viewport("mini") {
      font-size: 24px;
      scroll-margin-top: $space-l * 1.5;
      padding-right: $base-spacing * 3;
    }
  }
  .entry-link {
    padding: $base-spacing * 2;
    width: $base-spacing * 5;
    &:hover {
      .linkicon {
        visibility: visible;
      }
    }
  }
  .entry-products {
    display: flex;
    flex-direction: column;
    grid-gap: $space-s;
    margin-bottom: $space-s;
  }
  .entry-products-label {
    margin-bottom: $space-s;
  }

  .show-more-link {
    font-family: $font-family-default;
    display: flex;
    align-items: center;
    color: $blue-text;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }

  .cf:before,
  .cf:after {
    content: " "; /* 1 */
    display: table; /* 2 */
  }

  .cf:after {
    clear: both;
  }

  .icon {
    width: $base-spacing * 4;
    height: $base-spacing * 4;
  }
</style>
