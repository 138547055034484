<template>
  <Transition name="scrolltop-anim">
    <a
      v-if="scrollToTopVisible"
      class="scoll-to-top"
      :href="`#${contentId}`"
      aria-label="Scroll to top"
    >
      <SvgIcon icon="chevron-up" class="scroll-to-top_icon" />
    </a>
  </Transition>
</template>

<script setup lang="ts">
  import debounce from "~/lib/debounce"

  const scrollToTopVisible = ref(false)
  const contentId = useRuntimeConfig().public.mainContentId

  const checkScrollToTopVisibility = () => {
    if (window.scrollY > window.innerHeight / 2) {
      scrollToTopVisible.value = true
    } else {
      scrollToTopVisible.value = false
    }
  }
  const debouncedCheckScrollToTopVisibility = debounce(async () => {
    await checkScrollToTopVisibility()
  }, 150)

  onMounted(() => {
    window.addEventListener("scroll", () => {
      debouncedCheckScrollToTopVisibility()
    })
  })
</script>

<style scoped lang="scss">
  @keyframes wiggle-up {
    50% {
      transform: translateY(-5px);
    }
  }
  .scoll-to-top {
    background: $blue-100;
    color: $gray-800;
    box-shadow: $shadow;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    position: fixed;
    z-index: 300;

    display: flex;
    align-items: center;
    justify-content: center;
    bottom: $space-m;
    right: $space-m;

    transition: background 250ms ease-in-out;

    @include viewport("mini") {
      bottom: $space-s;
      right: $space-s;
    }
    &:hover {
      background: $blue-100;
      .scroll-to-top_icon {
        animation: wiggle-up 400ms ease-in-out 2;
      }
    }
  }

  .scroll-to-top_icon {
    width: 32px;
    height: 32px;
  }

  .scrolltop-anim-enter-active,
  .scrolltop-anim-leave-active {
    transition: opacity 250ms ease-in-out;
  }

  .scrolltop-anim-enter-from,
  .scrolltop-anim-leave-to {
    opacity: 0;
  }
</style>
