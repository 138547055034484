<template>
  <nuxt-link :to="urlPath" class="product-link">
    <div class="product-link-image-wrap">
      <ProductPicture
        html-role="none"
        :image="{ src: src, alt: alt, srcset: srcset }"
        sizes="40px"
      />
    </div>
    {{ name }}
  </nuxt-link>
</template>

<script>
  export default {
    props: {
      name: {
        type: String,
        default: "",
      },
      srcset: {
        type: Array,
        default: () => [],
      },
      src: {
        type: String,
        default: "",
      },
      alt: {
        type: String,
        default: "",
      },
      urlPath: {
        type: String,
        default: "",
      },
    },
  }
</script>

<style scoped lang="scss">
  .product-link {
    display: flex;
    align-items: center;
    font-family: $font-family-default;
    font-weight: bold;
    grid-gap: calc($space-s / 2);
    color: inherit;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
  .product-link-image-wrap {
    width: $base-spacing * 10;
    height: $base-spacing * 10;
    border-radius: $border-radius;
    overflow: hidden;
  }
</style>
