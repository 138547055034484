<template>
  <FragranceNoteGlossary
    v-if="!!FragranceElement && !!sortedNotes"
    :data="sortedNotes"
    :title="page.name"
    :text="getValue(FragranceElement, 'description') || ''"
  />
</template>

<script lang="ts" setup>
  import { deserialize } from "@alchemy_cms/json_api"
  import { useNotificationStore } from "~/pinia/notifications"
  const { api } = useApi()
  const { getValue } = useAlchemy()

  const props = defineProps<{
    page: AlchemyPage
  }>()

  const { data: sortedNotes } = await useAsyncData(
    "fragrance-note-glossary-products",
    () =>
      api("/jsonapi/fragrance_notes", {
        params: {
          fields: {
            product: "name,imageUrl,urlPath",
          },
          include: "products",
        },
      }),
    {
      transform(data) {
        const deserializedData = deserialize(data) as FragranceNote[]
        return Object.groupBy(deserializedData, (item) =>
          item.name[0].toUpperCase(),
        )
      },
    },
  )

  const FragranceElement = computed(() =>
    props.page.elements.find(
      (e: AlchemyElement) => e.name === "fragrance_note_glossary",
    ),
  )

  const nStore = useNotificationStore()

  useHead(usePageInfo(props.page))

  onMounted(() => {
    nStore.announce(props.page.title)
  })
</script>
